import { useToast, POSITION } from "vue-toastification";
const toast = useToast();

const handleResponse = (response, errors = {},message = '', flag = 'success') => {
    if(message) {
        if(flag === 'success') toast.success(message, { position: POSITION.TOP_CENTER })
        else toast.error(message, { position: POSITION.TOP_CENTER })
        return ''
    }
    if(response?.status === 200) toast.success(response?.data.message, { position: POSITION.TOP_CENTER })
    else if(response?.response?.status === 422) {
        errors.value = response?.response?.data?.errors
    } else if (response?.response?.data?.message) toast.error(response?.response.data.message, { position: POSITION.TOP_CENTER })
}
export default handleResponse;