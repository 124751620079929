<template>
  <div>
    <div>
      <b-card>
        <div class="w-100 text-uppercase">
          <h4 class="mb-3" v-if="!isEdit">Add New Service</h4>
          <h5 class="mb-3" v-if="isEdit">Edit Service</h5>
        </div>
        <form @submit.prevent="handleSubmit">
          <b-form-group>
            <!-- Title & Sub_Title -->
            <div class="mb-3">
              <b-row>
                <b-col>
                  <div>
                    <label for="title" class="font-weight-bolder">Title :</label>
                    <b-form-input v-model="servicePostForm.title" id="title" placeholder="Enter Service Title" >
                    </b-form-input>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="subTitle" class="font-weight-bolder">Sub Title :</label>
                    <b-form-input v-model="servicePostForm.sub_title" id="subTitle" placeholder="Enter Service Sub Title"
                      >
                    </b-form-input>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Categories  @change="changeArrToString"  v-model="servicePostForm" -->
            <div class="mb-3">
              <label for="categories" class="font-weight-bolder">Categories :</label>
              <b-form-checkbox-group
                class="d-flex justify-content-between align-items-center">
                <b-form-checkbox @change="changeArrToString" v-model="servicePostForm.category_id" v-for="c in categories" :key="c.id" :value="c.id" class="h4" size="lg">{{ c.name
                }}</b-form-checkbox>
              </b-form-checkbox-group>
            </div>

            <!-- Description -->
            <div class="mb-3">
              <label for="des" class="font-weight-bolder">Description :</label>
              <vue-editor v-model="servicePostForm.description"></vue-editor>
            </div>

            <!-- Contact Info -->
            <div class="mb-3">
              <label for="contact-info" class="font-weight-bolder">Contact Info :</label>
              <b-form-input v-model="servicePostForm.contact_info" id="contact-info" placeholder="Enter Contact Info"
                >
              </b-form-input>
            </div>

            <!-- Email & Phone -->
            <b-row class="mb-3">
              <b-col>
                <div>
                  <label for="email" class="font-weight-bolder">Email :</label>
                  <b-form-input v-model="servicePostForm.email" id="email" type="email" placeholder="example@gmail.com"
                    >
                  </b-form-input>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="phno" class="font-weight-bolder">Phone :</label>
                  <b-form-input v-model="servicePostForm.phone" id="phno" placeholder="Phone Number" >
                  </b-form-input>
                </div>
              </b-col>
            </b-row>

            <!-- Latitude & Longitude -->
            <div class="mb-3">
              <b-row>
                <b-col>
                  <div>
                    <label for="lat" class="font-weight-bolder">Latitude :</label>
                    <b-form-input v-model="servicePostForm.lat" id="lat" placeholder="example - 16.768148" >
                    </b-form-input>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="lon" class="font-weight-bolder">Longitude :</label>
                    <b-form-input v-model="servicePostForm.lon" id="lng" placeholder="example - 96.174930" >
                    </b-form-input>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Booking_status & Publish_status -->
            <b-row class="mb-3">
              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder">Booking Mode :</label>
                  <b-form-checkbox v-model="checked" @change="toggle(checked)" name="check-booking-button" switch>
                  </b-form-checkbox>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder">Publish :</label>
                  <b-form-checkbox v-model="publish" @change="toggle(publish)" name="check-publish-button" switch>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>

            <!-- States -->
            <div class="mb-3">
              <label for="states" class="font-weight-bolder">States :</label>
              <b-form-select v-model="servicePostForm.state_id" >
                <b-form-select-option disabled value="">Please Select a state</b-form-select-option>
                <b-form-select-option v-for="s in states" :key="s.id" :value="s.id">{{ s.name }}</b-form-select-option>
              </b-form-select>
            </div>

            <!-- Opening_time & Closing_time -->
            <b-row class="mb-3">
              <b-col>
                <div>
                  <label for="open" class="font-weight-bolder">Opening Time :</label>
                  <b-form-timepicker v-model="openTimeVal" locale="en" @context="openContext"
                    ></b-form-timepicker>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="close" class="font-weight-bolder">Closing Time :</label>
                  <b-form-timepicker v-model="closeTimeVal" locale="en" @context="closeContext"
                    ></b-form-timepicker>
                </div>
              </b-col>
            </b-row>

            <!-- Photos -->
            <div v-if="!isEdit" class="mb-4">
              <label for="contact-info" class="font-weight-bolder">Photos :</label>
              <div>
                <MyPhotoUpload @getPhotoId="uploadPhoto" />
              </div>
            </div>
          </b-form-group>

          <template v-if="!isEdit">
            {{ allUploaded() }}
          </template>

          <div class="d-flex flex-column flex-md-row">
            <div class="w-100 text-center mb-2 mb-md-0">
              <b-button type="button" variant="outline-primary" @click="() => router.go(-1)" class="col-md-10 col-lg-8">
                <!-- <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner> -->
                <span>Back</span>
              </b-button>
            </div>

            <div class="w-100 text-center" v-if="!isEdit">
              <b-button variant="primary" class="col-md-10 col-lg-8" type="submit">
                <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                <span v-else>Submit</span>
              </b-button>
            </div>

            <div class="w-100 text-center" v-if="isEdit">
              <b-button type="button" variant="primary" @click="updateServiceData(servicePostForm.id)" class="col-md-10 col-lg-8">
                <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
                <span v-else>Update</span>
              </b-button>
            </div>
          </div>
        </form>
      </b-card>
    </div>
  </div>
</template>
  
<script>
import store from "@/store";
// import MapLocationSelector from 'vue-latlng-picker';
import Table from "../components/Table.vue";
import { VueEditor } from "vue2-quill-editor";
import MyPhotoUpload from "../components/MyPhotoUpload.vue";
import router from "@/router";
import { useRouter } from "@core/utils/utils";
import handleResponse from '@/libs/toastResponse'
import {
  ref,
  reactive,
} from "@vue/composition-api";
import {
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormTimepicker,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTimepicker,
    BFormDatepicker,
    // MapLocationSelector,
    VueEditor,
    Table,
    MyPhotoUpload,
  },
  setup(props, { emit }) {
    const { router } = useRouter();
    const routerQuery = router.app._route.query;
    const spinner = ref(false);
    const checked = ref(true);
    const publish = ref(true);
    const isAllUploaded = ref(false);
    const categories = ref(JSON.parse(routerQuery.categories));
    console.log(categories);
    const states = ref(JSON.parse(routerQuery.states));
    // const users = ref(isService.value ? JSON.parse(routerQuery.users) : "");
    const isEdit = ref(JSON.parse(routerQuery.isEdit));
    const mulImgs = ref([]);
    const openTimeVal = ref(null);
    const closeTimeVal = ref(null);
    const selectedCategories = ref([]);
    const servicePostForm = reactive({
      id: "",
      title: "",
      category_id: [],
      // user_id: "",
      sub_title: "",
      description: "",
      contact_info: "",
      phone: "",
      email: "",
      lat: "",
      lon: "",
      booking_mode: 1,
      status: "active",
      lng : "96.122345566",
      state_id: "",
      opening_time: "",
      closing_time: "",
      photos: [],
    });

    //Datas Fetching
    //Functions
    // const locationUpdated = (latlng) => {
    //   latitude.value = latlng.lat;
    //   longitude.value = latlng.lng;
    // };
    // service categories
    const changeArrToString = () => {
      // servicePostForm.category_id = JSON.stringify(selectedCategories.value);
      // servicePostForm.category_id.push(...selectedCategories.value)
      console.log(servicePostForm.category_id)
    };
    const toggle = (c = checked.value, p = publish.value) => {
      c
        ? (servicePostForm.booking_mode = 1)
        : (servicePostForm.booking_mode = 0);
      p
        ? (servicePostForm.status = "active")
        : (servicePostForm.status = "unactive");
    };
    const uploadPhoto = (photoIds) => {
      mulImgs.value = [...mulImgs.value, photoIds];
      servicePostForm.photos = mulImgs.value
    };
    // Time
    const openContext = (ctx) => {
      servicePostForm.opening_time = ctx.value.slice(0, 5);
    };
    const closeContext = (ctx) => {
      servicePostForm.closing_time = ctx.value.slice(0, 5);
    };
    // Clear Datas
    const clearDatas = () => {
      servicePostForm.id = "";
      servicePostForm.title = "";
      servicePostForm.category_id = [];
      // servicePostForm.user_id = "";
      servicePostForm.sub_title = "";
      servicePostForm.description = "";
      servicePostForm.contact_info = "";
      servicePostForm.phone = "";
      servicePostForm.email = "";
      servicePostForm.lat = "";
      servicePostForm.lng = "";
      servicePostForm.booking_mode = 1;
      servicePostForm.photos = [];
    };

    //Data Posting
    const handleSubmit = () => {
      // spinner.value = true;
      store
        .dispatch("app-mypages/postDatas", {
          path: "services",
          payload: servicePostForm,
        })
        .then((response) => {
          spinner.value = false;
          isEdit.value = false;
          clearDatas();
          router.go(-1);
        }).catch((res) => {
          handleResponse(res);
        });
    };

    // Data Editing
    if (isEdit.value) {
      const editServiceData = () => {
        const editDatas = JSON.parse(routerQuery.editDatas);

        console.log(editDatas)
        servicePostForm.id = editDatas.id;
        servicePostForm.title = editDatas.title;
        console.log(editDatas.category_id)
        servicePostForm.category_id = editDatas.category_id;
        selectedCategories.value = editDatas.category_id;
        // servicePostForm.user_id = editDatas.user_id;
        servicePostForm.sub_title = editDatas.sub_title;
        servicePostForm.description = editDatas.description;
        servicePostForm.contact_info = editDatas.contact_info;
        servicePostForm.phone = editDatas.phone;
        servicePostForm.email = editDatas.email;
        servicePostForm.lat = editDatas.lat;
        servicePostForm.lng = editDatas.lng;
        servicePostForm.booking_mode = editDatas.booking_mode;
        servicePostForm.status = editDatas.status;
        servicePostForm.state_id = editDatas.state_id;
        openTimeVal.value = editDatas.opening_time.slice(0, 5);
        closeTimeVal.value = editDatas.closing_time.slice(0, 5);
      };
      editServiceData();
    }
    const updateServiceData = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/updateData", {
          path: "services",
          payload: servicePostForm,
        })
        .then((response) => {
          spinner.value = false;
          isEdit.value = false;
          clearDatas();
          router.go(-1);
        });
    };

    // Close Function
    const close = () => {
      isEdit.value = false;
      clearDatas();
    };

    // All Uploaded
    const allUploaded = () => {
      if (servicePostForm.title &&
        servicePostForm.category_id.length !== 0 &&
        servicePostForm.sub_title &&
        servicePostForm.description &&
        servicePostForm.contact_info &&
        servicePostForm.phone &&
        servicePostForm.email &&
        servicePostForm.lat &&
        servicePostForm.lng &&
        servicePostForm.photos.length !== 0) {
        isAllUploaded.value = true;
      }
    }

    return {
      spinner,
      isEdit,
      checked,
      publish,
      categories,
      states,
      // users,
      handleSubmit,
      toggle,
      uploadPhoto,
      openTimeVal,
      openContext,
      closeTimeVal,
      closeContext,
      servicePostForm,
      selectedCategories,
      changeArrToString,
      updateServiceData,
      close,
      allUploaded,
      isAllUploaded,
      router,
    };
  },
};
</script>
<style scoped></style>